'use client'
import Link from 'next/link'
import { twMerge } from 'tailwind-merge'
import { ILinkProps } from '~@Types/components/ILinkProps'

const rolloverClass =
  'transition-colors ease-out duration-500 cursor-pointer focus:outline-black-black focus:outline-2 focus:outline-offset-2'

export default function LinkItem({
  tagElt,
  type,
  href,
  target,
  replace,
  prefetch,
  scroll = true,
  className,
  ariaLabel,
  ariaCurrent,
  role,
  title,
  tabIndex,
  children,
  onClick,
  onMouseEnter,
  onMouseLeave,
  value,
  current,
  ...rest
}: ILinkProps) {
  const interactiveEltClassName = twMerge(rolloverClass, className)

  if ((tagElt === 'Link' || tagElt === 'a') && href) {
    const handleClick: React.MouseEventHandler<HTMLAnchorElement> =
      onClick as React.MouseEventHandler<HTMLAnchorElement>
    return (
      <Link
        aria-label={ariaLabel}
        aria-current={ariaCurrent}
        title={title}
        tabIndex={tabIndex}
        className={interactiveEltClassName}
        onClick={handleClick}
        href={href}
        replace={replace}
        prefetch={prefetch}
        scroll={scroll}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        target={target}
        {...rest}
      >
        {children}
      </Link>
    )
  }

  if (tagElt === 'button') {
    const handleClick: React.MouseEventHandler<HTMLButtonElement> =
      onClick as React.MouseEventHandler<HTMLButtonElement>
    return (
      <button
        className={interactiveEltClassName}
        type={type as 'button' | 'submit' | 'reset' | undefined}
        onClick={handleClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        value={value}
        role={role}
        {...rest}
      >
        {children}
      </button>
    )
  }

  const Element = tagElt === 'span' ? 'span' : 'div'
  return (
    <Element className={className} {...rest}>
      {children}
    </Element>
  )
}
