import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/next/src/components/atoms/keyfigure/keyfigure.tsx");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/next/src/components/atoms/link-item/link-item.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/next/src/components/cms/gtm-page-tracker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CarouselAlternate","CarouselAlternatePrevious","CarouselAlternateNext","CarouselAlternateContent","CarouselAlternateItem"] */ "/opt/atlassian/pipelines/agent/build/next/src/components/lib/carouselAlternate.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/next/src/components/molecules/scrolling-baseline/scrolling-baseline.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/next/src/components/molecules/teaser-card/teaser-card.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/next/src/components/molecules/teaser-highlight/teaser-highlight.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/next/src/components/organisms/section-corporate/section-corporate.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/next/src/components/organisms/section-hero/section-hero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/next/src/components/organisms/section-map/section-map.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/next/src/components/organisms/section-wall/section-wall.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/next/src/components/utils/locales-store-client-setter.tsx");
