import { useEffect, useState } from 'react'

export const useConsent = () => {
  const [hasConsent, setHasConsent] = useState<boolean>(false)

  useEffect(() => {
    const checkConsent = () => {
      const consent = localStorage.getItem('cookie-consent')
      setHasConsent(consent === 'accepted')
    }

    checkConsent()

    const handleConsentChange = (event: CustomEvent) => {
      setHasConsent(event.detail.consent === 'accepted')
    }

    window.addEventListener(
      'consentUpdate',
      handleConsentChange as EventListener
    )
    return () =>
      window.removeEventListener(
        'consentUpdate',
        handleConsentChange as EventListener
      )
  }, [])

  return { hasConsent }
}
