import { createSlice } from '@reduxjs/toolkit'
import { ILocalesStore } from '~@Types/store/ILocalesStore'

const initialState: ILocalesStore = {
  locales: ['fr', 'en'],
  customLocalLinks: [],
}

const localesSlice = createSlice({
  name: 'locales',
  initialState,
  reducers: {
    setCustomLocales: (state, action) => {
      state.locales = action.payload.locales
    },
    clearCustomLocales: (state) => {
      state.locales = initialState.locales
    },
    setCustomLocaleLinks: (state, action) => {
      state.customLocalLinks = action.payload.customLocalLinks
    },
    clearCustomLocaleLinks: (state) => {
      state.customLocalLinks = initialState.customLocalLinks
    },
  },
})

export const {
  setCustomLocales,
  clearCustomLocales,
  setCustomLocaleLinks,
  clearCustomLocaleLinks,
} = localesSlice.actions
export default localesSlice.reducer
