'use client'

import { useEffect, useCallback } from 'react'
import { usePathname } from 'next/navigation'

import { useGTM } from '~@Hooks/useGTM'

import { IGTMPageTracker } from '~@Types/components/IGTM'

export default function GTMPageTracker({
  title,
  language,
  category,
  subCategories,
  pageLocation,
}: IGTMPageTracker) {
  const pathname = usePathname()
  const { pageView } = useGTM()

  const handlePageView = useCallback(() => {
    pageView(pathname, title, language, category, subCategories, pageLocation)
  }, [pathname, title, language, category, subCategories, pageLocation])

  useEffect(() => {
    handlePageView()
  }, [handlePageView])

  return null
}
