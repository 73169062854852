import {
  GTMEvent,
  PageViewEvent,
  DownloadEvent,
  ContactCTAEvent,
  FormSubmitEvent,
  SearchEvent,
  JoinUsCTAEvent,
} from '~@Types/components/IGTM'

import { GTM_EVENTS } from '~@Utils/gtm/gtmConstants'

import { useConsent } from './useConsent'

export const useGTM = () => {
  const { hasConsent } = useConsent()

  const pushEvent = <T extends GTMEvent>(event: T) => {
    /* if (!hasConsent) {
      console.log('GTM Event blocked - no consent:', event)
      return
    } */

    if (
      typeof window !== 'undefined' &&
      process.env.NODE_ENV === 'production'
    ) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push(event)
    }

    if (process.env.NODE_ENV === 'development') {
      console.log('GTM Event (non-pushed) in development mode:', event)
    }
  }

  const pageView = (
    path: string,
    title: string,
    language: string,
    category?: string,
    subCategory?: string[],
    pageLocation?: string
  ) => {
    const pageViewEvent: PageViewEvent = {
      event: GTM_EVENTS.PAGE_VIEW,
      page_path: path,
      page_title: title,
      language,
      ...(category && { page_category: category }),
      ...(subCategory && { page_sub_category: subCategory }),
      ...(pageLocation && { page_location: pageLocation }),
    }

    pushEvent(pageViewEvent)
  }

  const trackDownload = (docName: string, docType: string) => {
    const downloadEvent: DownloadEvent = {
      event: GTM_EVENTS.DOWNLOAD,
      doc_name: docName,
      doc_type: docType,
    }

    pushEvent(downloadEvent)
  }

  const trackContactCTA = (pagePath: string) => {
    const contactEvent: ContactCTAEvent = {
      event: GTM_EVENTS.CONTACT_CTA,
      page_path: pagePath,
    }

    pushEvent(contactEvent)
  }

  const trackJoinUsCTA = (pagePath: string) => {
    const joinUsEvent: JoinUsCTAEvent = {
      event: GTM_EVENTS.JOIN_US_CTA,
      page_path: pagePath,
    }

    pushEvent(joinUsEvent)
  }

  const trackFormSubmit = (formType: string) => {
    const formEvent: FormSubmitEvent = {
      event: GTM_EVENTS.CONTACT_FORM,
      form_type: formType,
    }

    pushEvent(formEvent)
  }

  const trackSearch = (searchTerm: string, hasResults: boolean) => {
    const searchEvent: SearchEvent = {
      event: hasResults
        ? GTM_EVENTS.SEARCH_SUCCESSFUL
        : GTM_EVENTS.SEARCH_NO_RESULT,
      search_term: searchTerm,
    }

    pushEvent(searchEvent)
  }

  return {
    pushEvent,
    pageView,
    trackDownload,
    trackContactCTA,
    trackJoinUsCTA,
    trackFormSubmit,
    trackSearch,
  }
}
