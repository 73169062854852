import { IBlockPicture } from '~@Types/components/IBlockPicture'
import Image from 'next/image'
import { twMerge } from 'tailwind-merge'
const responsive = {
  max: 1600,
  steps: {
    mobile: 320,
    tablet: 640,
    desktop: 960,
    'desktop-large': 1280,
  },
}
const BlockPicture = ({
  src,
  alt,
  width,
  height,
  className,
  loading = 'lazy',
}: IBlockPicture) => {
  const devices = responsive.steps
  const sourcesList = Object.entries(devices)
    .reverse()
    .map(([device, value], index) =>
      typeof src === 'object' ? (
        <source
          key={index}
          srcSet={(src as Record<string, string>)[device]}
          media={`all and (min-width: ${value}px)`}
        />
      ) : null
    )
  const srcFallback = () => {
    if (!!src) {
      return typeof src === 'string' ? src : Object.values(src)[0]
    }
    return null
  }

  return (
    !!src && (
      <picture>
        {sourcesList}
        <Image
          className={twMerge('BlockPicture block mx-auto', className)}
          alt={alt}
          width={width}
          height={height}
          loading={loading}
          src={srcFallback()}
        />
      </picture>
    )
  )
}

export default BlockPicture
