'use client'
import { useAppDispatch } from '~@Store/hooks'
import {
  clearCustomLocaleLinks,
  setCustomLocaleLinks,
} from '~@Store/features/localesSlice'
import { useEffect, useCallback } from 'react'
import { ILocalLinks } from '~@Types/store/ILocalesStore'
interface IProps {
  customLocalLinks: ILocalLinks[]
}
const LocalesStoreClientSetter = ({ customLocalLinks }: IProps) => {
  const dispatch = useAppDispatch()

  const updateLocales = useCallback(() => {
    dispatch(setCustomLocaleLinks({ customLocalLinks }))
  }, [customLocalLinks, dispatch])

  useEffect(() => {
    updateLocales()
    return () => {
      dispatch(clearCustomLocaleLinks())
    }
  }, [updateLocales])

  useEffect(() => {
    window.addEventListener('routeChangeComplete', updateLocales)
    return () => {
      window.removeEventListener('routeChangeComplete', updateLocales)
    }
  }, [updateLocales])

  return <></>
}

export default LocalesStoreClientSetter
